<template>
  <div>
<!--    <el-row>
      &lt;!&ndash; 左侧 &ndash;&gt;
      <el-col :span="8" class="left">
        &lt;!&ndash; 个人信息 &ndash;&gt;
        <el-card class="box-card">
          <div class="user">
            <img src="../../assets/staffPhoto.png" alt="">
            <div class="userinfo">
              <p class="name">Admin</p>
              <p class="access">超级管理员</p>
            </div>
          </div>
          <div class="login-info">
            <p>上次登录时间：<span>2022-12-17</span></p>
            <p>上次登录地点：<span>浙江杭州</span></p>
          </div>
        </el-card>

        &lt;!&ndash; 表格 &ndash;&gt;
        <el-card class="box-card">
          <el-table :data="tableData">
            <el-table-column v-for="(value,key) in tableLabel" :key="key" :prop="key" :label="value">
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>

      &lt;!&ndash; 右侧 &ndash;&gt;
      <el-col :span="16" class="right">
        <div class="num">
          <el-card v-for="item in countData" :key="item.name" :body-style="{display:'flex',padding:0}">
            &lt;!&ndash; 动态绑定类样式 &ndash;&gt;
            <i class="icon" :class="`el-icon-${item.icon}`" :style="{background:item.color}"></i>
            <div class="detail">
              <p>{{item.value}}</p>
              <p>{{item.name}}</p>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      tableData: [
        {
          name: 'oppo',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        },
        {
          name: 'vivo',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        },
        {
          name: '苹果',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        },
        {
          name: '小米',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        },
        {
          name: '三星',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        },
        {
          name: '魅族',
          todayBuy: 100,
          monthBuy: 300,
          totalBuy: 800
        }
      ],
      tableLabel: {
        name: '品牌',
        todayBuy: '本月购买',
        monthBuy: '本月购买',
        totalBuy: '总共购买'
      },
      countData: [
        {
          name: '今日支付订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9'
        },
        {
          name: '今日收藏订单',
          value: 210,
          icon: 'star-on',
          color: '#ffb980'
        },
        {
          name: '今日未支付订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef'
        },
        {
          name: '本月支付订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9'
        },
        {
          name: '本月收藏订单',
          value: 210,
          icon: 'star-on',
          color: '#ffb980'
        },
        {
          name: '本月未支付订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef'
        }
      ]

    }
  },
  created () {
    this.sendHttp()
  },
  methods: {
    async sendHttp () {
    }
  }
}
</script>

<style lang="less" scoped>
.el-row{
  width: 100%;
}

.left{
  // background-color: aqua;
  padding-right: 10px;
  .box-card{
    margin-bottom: 20px;
  }
  .user {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #000;
  // background-color: #f40;

  img {
    display: inline-block;
    width: 100%;
    // height: auto;
    border-radius: 50%;
  }

  .userinfo {
    margin-left: 30px;
  }
}

.login-info {
  p {
    line-height: 20px;
    font-size: 14px;
    color: #999999;

    span {
      color: #666666;
      margin-left: 30px;
    }
  }
}

}

.num{
  display: flex;
  // 开启换行
  flex-wrap: wrap;
  justify-content: space-between;
  // width: 200px;

  .icon{
    width: 106px;
    height: 106px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
    margin-right: 20px;
  }

  .detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .el-card{
    width: 32%;
    margin-bottom: 20px;
  }
}
</style>
